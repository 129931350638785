.table-action-button {
    color: #367b88;
    cursor: pointer;
}

.wg-btn-primary {
    background-color: #367b88 !important;
    border: none;
}

.wg-btn-danger {
    background-color: #cf0000 !important;
    border: none;
}

.p-tabview-nav{
    display: flex;
    justify-content: space-evenly;
}

.p-toolbar{
    padding: 0.75rem !important;
}

:focus{
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

body{
    margin: 0;
    padding:0;
    height: 100%;
    font-family: 'Rubik';
    font-size: 14px;
}

.navitem-active {
    border-bottom: solid white;
}

.p-paginator {
    float: right;
}


.edit-pane{
    background-color: #FFF3F2;
    padding: 4%;
    border-radius: 10px;
}

.required::after {
    content: "*";
    color: red;
}

.red-btn {
    background-color: #FFF3F2;
}

.primary-btn {
    background-color: #FFF3F2;
}

.bg-red-light {
    background-color: #FFF3F2;
}

.field-header {
    color: rgb(37, 37, 112);
    margin-bottom: 10px;
    font-weight: bold;
}


.p-dialog .p-dialog-header {
    background: #EAECED !important;
    padding-left: 32px !important;
}

.serial-num-column {
    width: 75px;
}

.p-calendar .p-datepicker {
    width: 500px !important;
    left: -400px !important;
}